<!--
 * @Author: faf
 * @desc: 
 * @Date: 2022-09-16 09:22:16
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="shelves-wrapper">
    <!--列表区域-->
    <div class="tableWrapper bgFFF paddingB10">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column type="index" :label="$t('list.index')" width="80"></el-table-column>
        <el-table-column
					show-overflow-tooltip
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column
          label="操作"
					min-width="200px"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <div class="option-wrap">
              <span @click="previewHandler(scope.row)" class="hover-wrap">预览</span>
              <span
                @click="moveUpHandler(scope.row.id, scope.$index)"
                :class="scope.$index != 0 ? 'hover-wrap' : 'dis-wrap'"
                >上移</span
              >
              <span
                @click="moveDownHandler(scope.row.id, scope.$index)"
                :class="
                  scope.$index != tableData.length - 1 && tableData.length > 1
                    ? 'hover-wrap'
                    : 'dis-wrap'
                "
                >下移</span
              >
              <span @click="undercarriageHandler(scope.row)" class="hover-wrap">下架</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
		<preview-list :previewVisible="previewVisible" :temp="temp" @closeValue="closeValue"></preview-list>
  </div>
</template>
<script>
import previewList from '../previewList.vue'
export default {
	components: {
		previewList
	},
  props: {
    tableData: {
      default: function () {
        return [];
      }
    },
    loading: {
      default: function () {
        return false;
      }
    }
  },
  data() {
    return {
			previewVisible: false,
			temp: {},
      tableCols: [
        {
          prop: "title",
          label: "标题",
          width: "366"
        },
        {
          prop: "title2",
          label: "发布渠道",
          width: "250",
          formatter: (value) => {
            let list = value.releaseType.split(','), strList = []
            list.forEach(ele => {
              if (ele === '1') {
                strList.push('APP')
              } else if (ele === '2') {
                strList.push('微信小程序')
              } else if (ele === '4') {
                strList.push('支付宝小程序')
              }
            })
            return strList.join('、');
          }
        },
        {
          prop: "updateTime",
          label: "最后编辑日期",
          width: ""
        }
      ]
    };
  },
  methods: {
		closeValue() {
      this.previewVisible = false;
    },

    /**
     * 预览
     */
    previewHandler(obj) {
			this.previewVisible = true;
			this.temp = obj;
		},

    /**
     * 上移、下移
     */
    soreHandler(id, targetId) {
      this.$axios
      .get("/acb/2.0/question/sort", {
        data: {
          id: id,
          targetId: targetId
        },
      })
      .then((res) => {
        if (res.state === 0) {
          this.$emit('searchData')
        }
      });
    },

    /**
     * 上移
     */
    moveUpHandler(id, index) {
      if (index <= 0) {
        return;
      }
      let upDate = this.tableData[index - 1];
      // this.tableData.splice(index - 1, 1);
      // this.tableData.splice(index, 0, upDate);
      this.soreHandler(id, upDate.id)
    },

    /**
     * 下移
     */
    moveDownHandler(id, index) {
      if (index === this.tableData.length - 1) {
        return;
      }
      let downDate = this.tableData[index + 1];
      // this.tableData.splice(index + 1, 1);
      // this.tableData.splice(index, 0, downDate);
      this.soreHandler(id, downDate.id)
    },

    /**
     * 下架
     */
    undercarriageHandler(row) {
      this.$axios
      .get("/acb/2.0/question/state", {
        data: {
          id: row.id,
          state: 0
        },
      })
      .then((res) => {
        if (res.state === 0) {
          this.$message({
            message: res.desc,
            type: "success",
          });
          this.$emit('searchData')
        }
      });
    }
  }
};
</script>
<style lang="stylus" scoped>
.shelves-wrapper {
  .option-wrap span {
    padding-right: 16px;
  }

  .icon-wrap {
    flex: 1;
    padding-right: 2px;
    font-size: 20px;
    font-weight: bold;
    vertical-align: middle;
  }

	.hover-wrap {
		color: #1D70FF;
	}

  .hover-wrap:hover {
    cursor: pointer;
  }

  .dis-wrap {
    color: #999;
  }
}
</style>
<style>
.shelves-wrapper .el-table th.el-table__cell {
  background-color: #f3f7ff !important;
}

.shelves-wrapper .el-table th.el-table__cell > .cell {
  color: rgba(0, 0, 0, 0.85);
}

/* #el-tooltip-4512 {
  background: #fff !important;
  color: rgba(0,0,0,0.65) !important;
}

#el-tooltip-4512 .popper__arrow {
	border-style: none;
} */
</style>
