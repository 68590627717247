<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.title')">
                <el-input v-model.trim="formInline.title" placeholder="请输入标题"></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Release_channel')">
                <el-select v-model.trim="formInline.releaseType" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.type"
                    v-for="value in msgType"
                    :key="value.type"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="primary" icon="el-icon-plus" @click="addQuestion"
                >新建问题</el-button
              >
              <el-button
                :class="shelvesType == 1 ? 'el-shelves' : 'un-shelves'"
                size="small"
                @click="shelvesHandler(1)"
                >已上架</el-button
              >
              <el-button
                :class="shelvesType == 1 ? 'un-shelves' : 'el-shelves'"
                size="small"
                @click="shelvesHandler(0)"
                >未上架</el-button
              >
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
            </div>
          </div>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table-wrapper bgFFF paddingB10">
        <!-- <div style="padding-left: 20px">
          <el-button
            :class="shelvesType == 1 ? 'el-shelves' : 'un-shelves'"
            size="small"
            @click="shelvesHandler(1)"
            >已上架</el-button
          >
          <el-button
            :class="shelvesType == 1 ? 'un-shelves' : 'el-shelves'"
            size="small"
            @click="shelvesHandler(0)"
            >未上架</el-button
          >
        </div> -->
        <shelves
          v-if="shelvesType == 1"
          :loading="loading"
          :tableData="tableData"
          @searchData="searchData"
        ></shelves>
        <un-shelves
          v-else
          :loading="loading"
          :tableData="tableData"
          @updateHandler="updateHandler"
          @searchData="searchData"
        ></un-shelves>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Shelves from "./components/shelves";
import UnShelves from "./components/unShelves";
export default {
  name: "shortMessageManage",
  data() {
    return {
      total: 0,
      pageSize: 15,
      pageNum: 1,
      shelvesType: 1,
      msgType: [
        {
          type: 1,
          desc: "APP",
        },
        {
          type: 4,
          desc: "支付宝小程序",
        },
        {
          type: 2,
          desc: "微信小程序",
        },
      ],
      loading: false,
      tableData: [],
      formInline: {
        releaseType: "",
      },
    };
  },

  watch: {
    shelvesType(newVal) {
      console.log("88", newVal);
    },
  },

  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },

    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/question/query", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            state: this.shelvesType,
            releaseType: this.formInline.releaseType,
            title: this.formInline.title,
          },
        })
        .then((res) => {
          this.loading = false;
          this.total = res.value.total * 1 || 0;
          this.tableData = res.value.list;
        });
    },

    addQuestion() {
      this.$router.push({
        path: "commonInfo",
        // query: { informationId: data.informationId }
      });
    },

    updateHandler(data) {
      let param = {
        id: data.id,
        title: data.title,
        content: data.content,
        releaseType: data.releaseType,
      };
      this.$router.push({
        path: "commonInfo",
        query: param,
      });
    },

    shelvesHandler(el) {
      this.shelvesType = el;
      sessionStorage.setItem("saveShelvesType", el);
      this.searchData();
    },
  },
  components: {
    Shelves,
    UnShelves,
  },
  beforeDestroy() {},
  mounted() {
    // if (this.$route.query.shelvesType) {
    //   this.shelvesType = this.$route.query.shelvesType;
    //   this.shelvesHandler(this.$route.query.shelvesType);
    // }
    if (sessionStorage.getItem("saveShelvesType")) {
      this.shelvesHandler(sessionStorage.getItem("saveShelvesType"));
    } else {
      this.searchData();
    }
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.table-wrapper {

  .el-shelves, .un-shelves {
    padding: 4px 12px;
    line-height: 22px;
    margin-bottom: 8px;
    background: #fff;
  }

  .el-shelves {
    color: #527BFF;
    border: 1px solid rgba(82, 123, 255, 0.12);
  }

  .un-shelves {
    color: #425466;
    border: 1px solid rgba(122, 130, 138, 0.12);
  }
}
</style>
